
import React, { useState } from 'react'
import {Link, Navigate} from 'react-router-dom';


import logo from "../images/logo192.png"
import dummy from "../images/dummy.webp"
import { useAuthContext } from '../hooks/useAuthContext';




export default function CreateAdmin() {
  const [thumbnail,setThumbnail] = useState('')
  const [thumbnailError, setThumbnailError] = useState(false)
  const [inputError, setInputError] = useState('')
  const [filepath, setFilepath] = useState('')
  const [username,setUsername] = useState('')
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [password, setPassword] = useState('')
  const [cnfpassword, setCnfPassword] = useState('')
  const [perror, setPerror] = useState('')
  const {signup, success,isPending,errors} = useAuthContext()
  

  


  const handleFileChange = (e) =>{

    setThumbnail(null)
    let selected = e.target.files[0]
   if(selected){
          if(!selected.type.includes('image')){
            setThumbnailError('selected file must be an image')
            return
          }
          if(selected.size > 2000000){
            setThumbnailError('file size is more than allowed')
            return
          }

          setThumbnailError(null)
          setThumbnail(selected)
          setFilepath(URL.createObjectURL(selected))
   
    
      }
   }
  
  


 
  const handleSubmit =(e)=>{
    e.preventDefault()
   

    const inputs = {
      username:username,  
      firstName:firstName,
      lastName:lastName,
      password:password,
      confirmation_password:cnfpassword,
      thumbnail:thumbnail
     
    }
         setInputError('')
        if(password == ''){
          setInputError('enter password')
          return
        }  
       if(cnfpassword == '' || password !== cnfpassword){
          setInputError("confirm password should match password")
          return
       }
      

        signup(inputs)
     
  }


  if(!success){
   
    return (
      <div className="fixed max-w-8/12 left-52 right-0 min-h-screen text-center  bg-themel3">
      <div className=' bg-themel3 flex items-center text-center -z-50 pt-10 pb-20'>
                
           <div className=' max-w-xl mx-auto flex-1 flex flex-col items bg-themel3 center p-3 overflow-auto'>
                <h1 className='text-4xl bg-themeblue text-themel2 font-thin py-4 px-6 mb-5'>Create User</h1>
              <form onSubmit={handleSubmit}  className='flex flex-col space-y-2'>
                <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer'>
                   <input type="file" 
                   placeholder='family name' 
                   className=' opacity-0 py-2 px-3 text-sm font-bold rounded bg-themel4 cursor-pointer' 
                   onChange={handleFileChange}
                 
                   />
                    <p className='w-full text-right md:text-center'>click above to select your photo</p>
                    {filepath && <img src={filepath} alt="" className='absolute w-16 h-16 top-1' />}
                    {!filepath && <img src={dummy} alt="" className='absolute w-16 h-16 top-1' />}
                </button>
                 {thumbnailError && <p className=' text-red-600'>{thumbnailError}</p>}
                 <input type="text"
                  placeholder='username'
                  className='py-2 px-3 text-sm font-bold rounded bg-themel4'
                  onChange={(e)=>setUsername(e.target.value)}
                  value={username}
                  required
                    />
                  
                 <input type="text"
                  placeholder='first_name'
                  className='py-2 px-3 text-sm font-bold rounded bg-themel4'
                  onChange={(e)=>setfirstName(e.target.value)}
                  value={firstName}
                  required
                    />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.firstName}</p>}
                 <input type="text"
                 placeholder='last name' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setlastName(e.target.value)}
                 value={lastName}
                 required
                   />
               {errors && <p className='text-xs text-red-600 font-bold'>{errors.lastName}</p>}
               
                 <input type="password" 
                 placeholder='password' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setPassword(e.target.value)}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.password}</p>}
                  <input type="password" 
                 placeholder='confirm password' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setCnfPassword(e.target.value)}
                 required
                 />
                  {inputError && <p className='text-red-600 text-sm font-bold'>{inputError}</p>}
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.confirmation_password}</p>}
                 { perror && <p className='text-sm text-red-500'>{perror}</p>}
                 {!isPending && <button onClick={handleSubmit} type="submit" className='py-3 px-4 bg-themed3 text-themel4 rounded-lg'>Submit</button>}
                 {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-white text-sm font-bold'>Signing you up, Please wait....</p>
                   
                  </div>}
                
              
              </form>

             
             
        </div>

      </div>
 </div>
   )
  }else{
    return (
       <Navigate to="/dashboard" />
    )
  }

  
  
}

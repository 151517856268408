import { useState } from "react"
import { makeRequest } from "../axios"


export default function useData() {
    const [ucount,setUcount] = useState()
    const [matchdata, setMatchData] = useState()
    const [subdata,setSubdata] = useState()
   
 

    const getdata =()=>{
       makeRequest.get("/userdata")
       .then((res)=>{
          setUcount(res.data.usercount)
          setMatchData(res.data.matchdata)
       })
    }

    const getSubs = ()=>{
        makeRequest.get("/subdata")
        .then((res)=>{
           setSubdata(res.data.subdata) 
          
        })
    }
    
    return {getdata, ucount,matchdata, getSubs, subdata}
}

import { useState } from "react"
import { Link } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo512.webp"
import dummy from "../images/dummy.webp"
import { Photourl } from "../Photourl"


export default function MobileNav() {

    const [menuOpen, setMenuOpen] = useState(false)
    const {currentUser,token, logout} = useAuthContext()
    const {photourl} = Photourl()
   
      const inputs ={
        token:token
      }
    
   const handleClick = (e)=>{
    e.preventDefault()
      logout(inputs)
      setMenuOpen(false)
   }


    return (
     <div className="fixed w-full top-0  bg-themel4 z-50 md:hidden p-1">
           
        <div className=" max-w-7xl mx-auto p-1 flex items-end justify-end">
        {!menuOpen && (
           <div onClick={()=> setMenuOpen(!menuOpen)} class="space-y-1 md:hidden cursor-pointer z-20 text-right">
           <div className="w-6 h-0.5 bg-black"></div>
           <div className="w-6 h-0.5 bg-black"></div>
           <div className="w-6 h-0.5 bg-black"></div>
         </div>
        )}

        {menuOpen && (
           <div onClick={()=> setMenuOpen(!menuOpen)} class=" md:hidden cursor-pointer z-20">
           <div className=" text-xs font-bold text-white">close</div>
         
           
         </div>
        )}
         
          {menuOpen && (
             <div id="menu" className=" flex flex-col items-center justify-center space-y-4 bg-themed3 min-h-max absolute left-0 top-0 w-full p-10  rounded-b-3xl  text-themel4  text-center ease-in ">
               
               <div className="w-full flex flex-col items-center  ">
                 <Link to="/"><img src={logo} alt=""  onClick={()=>setMenuOpen(false)}  className=" w-24 h-12 cursor-pointer"/></Link>
               </div>
               
                 {currentUser?
           <>
               {currentUser.profile_pic && <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />}
               {!currentUser.profile_pic && <img src={dummy} alt="" className=" w-6 h-6 rounded-full" />}
               <Link><p className="text-lg font-thin text-themel4">{currentUser.first_name} {currentUser.last_name}</p></Link>
               {currentUser.super_admin == 1 && <Link to="/createuser"><p  onClick={()=>setMenuOpen(false)}  className="text-lg font-medium text-themel4 ">Create User</p></Link>}
               <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logout</button>
               <Link to="/dashboard"><p  onClick={()=>setMenuOpen(false)}  className="text-sm font-medium text-themel4 ">Dashboard</p></Link>
               <Link to="/users"><p  onClick={()=>setMenuOpen(false)}  className="text-sm font-medium text-themel4 ">Users</p></Link>
               <Link to="/matches"><p  onClick={()=>setMenuOpen(false)}  className="text-sm font-medium text-themel4 ">Matches</p></Link>
                <Link to="/subscriptions"><p  onClick={()=>setMenuOpen(false)}  className="text-sm font-medium text-themel4 ">Subscriptions</p></Link>
               <Link><p  onClick={()=>setMenuOpen(false)}  className="text-sm font-medium text-themel4 ">Communications</p></Link>
             </>
             :
             <>
                <Link to="/adminlogin"><p  onClick={()=>setMenuOpen(false)}  className="text-lg font-medium text-themel4 ">Login</p></Link>
             </>  
        }
            
         
         </div>
          )}
        </div>
        </div>
    )
}
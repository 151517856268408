import bg from "../images/users.jpg"

export default function Matches({match}) {
  return (
    <div className=' w-80 h-72 flex flex-col items-center space-y-2 mr-2 rounded-2xl border shadow-2xl border-themed3' style={{"backgroundImage":"url('https://api.csimarital.in/storage/images/bgimage.jpg')", "backgroundRepeat":"none", "backgroundSize":"cover"}}>
        <h1 className="text-4xl mt-2 font-extrabold text-themed3">Maches</h1> 
        <h1 className=" text-9xl font-extrabold text-orange-500">{match.matchcount}</h1>
        <div className="flex items-center justify-between space-x-5">
             <div className="flex flex-col items-center justify-center">
                 <p className="text-xl font-medium text-indigo-800">Brides</p>
                   <p className="text-3xl font-extrabold text-orange-700">{match.brides}</p>
             </div>
             <div className="flex flex-col items-center justify-center">
             <p className="text-xl font-medium text-indigo-800">Grooms</p>
             <p className="text-3xl font-extrabold text-orange-700">{match.matchcount - match.brides}</p>
             </div>
        </div>
         
    </div>
  )
}

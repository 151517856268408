import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { makeRequest } from '../axios'
import logo from "../images/logo512.webp"
import { Photourl } from '../Photourl'
import { format } from 'date-fns'

export default function User({id, handleClose}) {
    const [userSingle,setUserSingle] = useState()
    const [isPending,setIsPending]= useState(false)
    const {photourl} = Photourl()

    useEffect(()=>{
        async function go(){
            setIsPending(true)
          await makeRequest.get(`/getuser/${id}`)
          .then((res)=>{
              setUserSingle(res.data.user)
              setIsPending(false)
          })
        }
        go()
    },[])

     
  
        return (
            <>
            {!isPending && (
               <div>
                 {userSingle && (
                <div className="fixed top-0 right-0 bottom-0 left-0 bg-themed3 opacity-90  flex flex-col items-center overflow-scroll z-10">
                    <div className=" max-w-2xl bg-white p-5 mt-3 md:mt-10 opacity-100 rounded-lg shadow-xl z-50">
                         <div className="w-full text-end">
                            <button onClick={handleClose}  className="text-sm font-bold text-black ">close</button>
                         </div>
                      <div className=" min-h-screen max-w-5xl mt-14 mx-auto p-4 flex flex-col items-center space-y-5">
                            <img src={`${photourl}/avatars/${userSingle.profile_pic}`} alt="" className=" w-48 h-40  object-cover" />
                            <h1 className="text-2xl font-bold text-themed3 ">{userSingle.family_name}{" "}{userSingle.first_name}{" "}{userSingle.last_name}</h1>
                            <h2>Email: {userSingle.email}</h2>
                            <h2>Mobile: {userSingle.mobile}</h2>
                            <h2>Address: {userSingle.address}</h2>
                            <h2>City: {userSingle.city}</h2>
                            <h2>Diocese: {userSingle.diocese_name}</h2>
                            <h2>Church name: {userSingle.church_name}</h2>
                           
                                { userSingle.razor_payment && <h2 className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">Subscription up to: {format(new Date(userSingle.razor_payment['expiry']), "dd-MM-yyyy")}</h2>} 
                                { !userSingle.razor_payment && <Link to="/payment"><p  className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">No subscription </p></Link>} 

                          
                            <p className="text-xl font-bold text-themed2 font-serif"> Matches posted under the account </p>
                            
                            {userSingle.matche && 
                                <>
                                <h1 className="text-2xl font-bold text-themed3 ">{userSingle.matche.family_name}{" "}{userSingle.matche.first_name}{" "}{userSingle.matche.last_name}</h1>
                                 <img src={`${photourl}/bust/${userSingle.matche.bust_image}`} alt="" className=" w-48 h-40  object-cover" />
                                 <img src={`${photourl}/full/${userSingle.matche.full_image}`} alt="" className=" w-48 h-40  object-cover" />
                                 <img src={`${photourl}/family/${userSingle.matche.family_image}`} alt="" className=" w-48 h-40  object-cover" />
                                 <table className=" table-fixed">
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.fname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.mname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Date of Birth</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.date_of_birth}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Qualifications</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.eduqual}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Working as </th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.cadre}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Nature of job</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.desgn}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Organization Type</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.occu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Place of Work</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.place_of_work}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.foccu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{userSingle.matche.moccu}</td>
                        </tr>
                       
                     </table>
                                 </>
                          }
                         </div>  
                      </div>
                    </div>
                 )}
             
               </div>
            )}
           {isPending && (
               <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                    <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                    <p className='text-white text-sm font-bold'> Please wait....</p>
             </div> 
           )}

            </>
          )
  

 
}

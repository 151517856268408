import logoImg from "../images/csim1.png"
import bgimg from "../images/bgcsim.png"


export default function Home() {
  return (
    <div className='w-full bg-themeblue relative -z-10' style={{ maxHeight:'800px'}}>
        
      <div className=" md:flex md:items-center justify-center md:space-x-7 " >
            <img src={bgimg} alt="" className="" style={{ maxHeight:'450px'}} />
            <div className=" md:block md:mb-0 flex flex-col items-center justify-end space-y-3">
              <h1 className=" text-5xl font-bold text-themel3 shadow-md leading-6 mb-4">CSIMarital</h1>
               <hr  className="text-white font-bold text-xl" />
               <p className="text-xl font-bold text-themel3">Admin Panel</p>
            </div>
            
      </div> 
     
    </div>
    
  )
}
